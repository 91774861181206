import React, { useState } from 'react';
import {Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import {useStaticQuery, graphql} from 'gatsby'
import CharacterCard from '../../../components/charactercard'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import classnames from 'classnames';
import PodcastContentArea from '../../podcast'
import {CentraliaContentWrap, CentraliaContentTop, CentraliaList, CentraliaContentBottom, CentraliaImg} from './centralia-content-area.stc'

const CentraliaContentArea = (props) => {
    const centraliaData = useStaticQuery(graphql `
        query CentraliaContentQuery {
            aboutcharacterJson(id: {eq: "centralia-page-content"}) {
                top_content {
                    heading
                    content {
                        text1
                        text2
                    }
                }
                list_left {
                    heading
                    items
                }
                list_right {
                    heading
                    items
                }
                bottom_content {
                    heading
                }
            }
            allCharacterJson(
            filter: {category: {in: ["Centralia", "The Resonance"]}}) {
                        totalCount
                        nodes {
 
                                id
                                title
                                category
                                desc
                                actor
                                header
                                link
                                tags
                                site
                                image {
                                        childImageSharp {
                                            fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
                                                ...GatsbyImageSharpFluid_withWebp
                                                presentationHeight
                                                presentationWidth
                                            }
                                        }
                                    }

                            }
                    
                    }
                    allPodcastRssFeedEpisode(filter: {item: {itunes: {season: {eq: "1"}}}}) {
                    nodes {
                    item {
                        title
                        content
                        content_encoded
                        guid
                        link
                        pubDate
                        enclosure {
                            url
                        }
                        itunes {
                        duration
                        subtitle
                        summary
                        season
                        episode
                        image 
                        }
                    }
                    }
                }

                    }      
            
    `)

    const characters = centraliaData.allCharacterJson.nodes
    const [activeTab, setActiveTab] = useState('1');
    const podcasts = centraliaData.allPodcastRssFeedEpisode.nodes
    const {top_content, list_left, list_right, bottom_content} = centraliaData.aboutcharacterJson
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
      }
    const {topHeadingCSS, textCSS, listHeadingCSS, bottomHeadingCSS} = props;
    return (
        <CentraliaContentWrap>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10} xl={8}>
                        {top_content && (
                            <CentraliaContentTop>
                                {top_content.heading && <Heading {...topHeadingCSS}>{top_content.heading}</Heading>}
                                {top_content.content.text1 && <Text {...textCSS}>{top_content.content.text1}</Text>}
                                {top_content.content.text2 && <Text {...textCSS}>{top_content.content.text2}</Text>}
                            </CentraliaContentTop>
                        )}
                        <Row>
                            {list_left && (
                                <Col lg={6}>
                                    {list_left.heading && <Heading {...listHeadingCSS}>{list_left.heading}</Heading>}
                                    {list_left.items && (
                                        <CentraliaList>
                                            {list_left.items.map((item, i) => <li key={`left-list-${i}`}>{item}</li>)}
                                        </CentraliaList>
                                    )}
                                </Col>
                            )}
                            {list_right && (
                                <Col lg={6}>
                                    {list_right.heading && <Heading {...listHeadingCSS}>{list_right.heading}</Heading>}
                                    {list_right.items && (
                                        <CentraliaList>
                                            {list_right.items.map((item, i) => <li key={`left-right-${i}`}>{item}</li>)}
                                        </CentraliaList>
                                    )}
                                </Col>
                            )}
                        </Row>
                        <Nav tabs>
                                <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    <Heading {...bottomHeadingCSS}>Our Characters</Heading>
                                </NavLink>
                                </NavItem>
                                <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                   <Heading {...bottomHeadingCSS}>Season Episodes</Heading>
                                </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                <CentraliaContentBottom>
                                {characters &&  <CharacterCard characters={characters} />}
                                </CentraliaContentBottom>
                                </TabPane>
                                <TabPane tabId="2">
                                <CentraliaContentBottom>
                                {podcasts && <PodcastContentArea podcasts={podcasts}/>}
                                </CentraliaContentBottom>
                                </TabPane>
                            </TabContent>
                    </Col>
                </Row>
            </Container>
        </CentraliaContentWrap>
    )
}

CentraliaContentArea.defaultProps = {
    topHeadingCSS: {
        as: 'h4',
        color: 'secondaryColor',
        lineHeight: 1.375,
        mb: '17px'
    },
    textCSS: {
        mb: '26px'
    },
    listHeadingCSS: {
        as: 'h5',
        mb: '14px'
    },
    bottomHeadingCSS: {
        as: 'h5',
        mb: '24px'
    }
}

export default CentraliaContentArea


  