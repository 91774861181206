import React from "react"
import SEO from '../components/seo'
import Layout from "../containers/layout/layout"
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import CentraliaTitleArea from '../containers/centralia/centralia-title-area'
import CentraliaBannerArea from '../containers/centralia/centralia-banner-area'
import CentraliaContentArea from '../containers/centralia/centralia-content-area'
import ArtworkArea from '../containers/global/artwork'
 
const CentraliaPage = () => (
  <Layout>
    <SEO 
        title="Centralia" 
        description="Centralia Page description"
        keywords={["character", "design", "portfolio"]}
    />
    <Header/>
    <div className="main-content">
        <CentraliaTitleArea/>
        <CentraliaBannerArea/>
        <CentraliaContentArea/>
        <ArtworkArea/>   
    </div>
    <Footer/>
  </Layout>
)

export default CentraliaPage
